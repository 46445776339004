<script setup>
// import { useAuthStore } from '~/store/auth.js'

import { onBeforeMount } from 'vue'

definePageMeta({ layout: 'default', middleware: ['authenticated'] })

// const user = useAuthStore().user
onBeforeMount(() => {
  return navigateTo('/quote-cases/create')
})
</script>
